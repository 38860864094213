"use client";
import React from "react";

import {
  Typography,
  Checkbox,
  MenuItem,
  ListItemText,
  FormControl,
  type SxProps,
  Box,
} from "@mui/material";

import colors from "@/lib/palette";

import { ResourceTypeModelType } from "@/shared/api/resources";
import { ClickOutside, Icon } from "../components";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//       transform: "translateX(-70px)",
//     },
//   },
// };

type ComboFilterProps = {
  value: string[];
  types: ResourceTypeModelType[];
  onSelect: (filter: string, value: string) => void;
  sx?: SxProps;
  selectSx?: SxProps;
};

export default function ResourceTypesDropdownFeature({
  value,
  types,
  selectSx,
  sx,
  onSelect,
}: ComboFilterProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelectType = (type: string) => {
    const newValue = value.includes(type)
      ? value.filter((item) => item !== type)
      : [...value, type];

    //   onSelect(
    //   "resourceType",
    //   typeof value === "string" ? value : value.join(",")
    // );

    onSelect("resourceType", newValue.join(","));
  };

  return (
    <FormControl
      sx={{
        display: "block",
        ...sx,
      }}
    >
      <ClickOutside onClickOutside={handleClose}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.white,
              borderRadius: 8,
              border: "1px solid",
              borderColor: colors.black400,
              cursor: "pointer",
              minHeight: "41px",
              minWidth: "82px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: "8px",
              py: "4px",

              "&:hover": {
                borderColor: "rgba(155, 168, 183, 1)",
              },

              ...selectSx,
            }}
            onClick={handleToggle}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "18px",
                color: colors.black700,
                marginRight: "8px",
              }}
            >
              Type
              {value.length > 0 ? ` (${value.length})` : ""}
            </Typography>

            <Icon icon="arrow-down" width={"18px"} color={colors.black700} />
          </Box>

          {open && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 4,
                top: "calc(100% + 12px)",
                right: 0,
                backgroundColor: colors.white,
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                overflowY: "auto",
              }}
            >
              {types.map((tag) => (
                <MenuItem
                  key={tag.value}
                  value={tag.value}
                  onClick={() => handleSelectType(tag.value)}
                >
                  <Checkbox checked={value.includes(tag.value as string)} />
                  <ListItemText
                    primary={`${tag.name} ${
                      tag.resources_count ? `(${tag.resources_count})` : ""
                    }`}
                  />
                </MenuItem>
              ))}
            </Box>
          )}
        </Box>
      </ClickOutside>

      {/* <Select
        multiple
        displayEmpty
        value={value || []}
        onChange={handleChange}
        input={<OutlinedInput label="Keyword" />}
        renderValue={(selected) => {
          return (
            <Typography variant="subtitle1">
              Type
              {selected.length > 0 ? ` (${selected.length})` : ""}
            </Typography>
          );
        }}
        MenuProps={MenuProps}
        sx={{
          height: "48px",
          backgroundColor: colors.white,
          borderRadius: 2,
          "& fieldset": {
            top: 0,
            borderColor: `${colors.steel} !important`,
            borderWidth: "1px !important",
            "& legend": {
              display: "none",
            },
          },

          ...selectSx,
        }}
      >
        {types.map((tag) => (
          <MenuItem key={tag.value} value={tag.value}>
            <Checkbox checked={value.includes(tag.value as string)} />
            <ListItemText
              primary={`${tag.name} ${
                tag.resources_count ? `(${tag.resources_count})` : ""
              }`}
            />
          </MenuItem>
        ))}
      </Select> */}
    </FormControl>
  );
}
